import {
  ApolloClient,
  ApolloLink,
  ApolloQueryResult,
  FetchResult,
  HttpLink,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from "@apollo/client/main.cjs";
import { RetryLink } from "@apollo/client/link/retry/retry.cjs";
import { StatusCodes } from "http-status-codes";

import { AppReqResponse, FailureRes, ResType, SuccessRes } from "../req";
import { SurfaceableError } from "../utils/SurfaceableError";

export interface ShopifyExtensions {
  cost: {
    requestedQueryCost: number;
    actualQueryCost: number;
    throttleStatus: {
      maximumAvailable: number;
      currentlyAvailable: number;
      restoreRate: number;
    };
  };
}

export enum ApiVersion {
  April2024 = "2024-04",
  Unstable = "unstable",
  Unversioned = "unversioned",
}

export const UsedApiVersion = ApiVersion.April2024;
export const SHOPIFY_ADMIN_API_PREFIX = "/admin/api";
export const APOLLO_RETRY_INITIAL_DELAY = 1000;
export const APOLLO_RETRY_MAX_DELAY = 10000;
export const APOLLO_RETRY_MAX_ATTEMPTS = 3;

export function apolloQuery<ReturnType, QueryVariables extends OperationVariables>(
  queryOptions: QueryOptions<QueryVariables>,
  client: ApolloClient<object>
): Promise<AppReqResponse<ApolloQueryResult<ReturnType>>> {
  return client
    .query<ReturnType, QueryVariables>(queryOptions)
    .then(body => {
      if (body.errors?.length) {
        throw {
          status: 400,
          statusText: body.errors[0].extensions?.code,
          message: JSON.stringify(body.errors),
        };
      }

      return {
        type: "success" as ResType,
        body,
      } as SuccessRes<ApolloQueryResult<ReturnType>>;
    })
    .catch(error => {
      const message = (error?.message as string) || "";
      const status = parseInt(error?.status, 10) || 400;

      return {
        type: "error" as ResType,
        status,
        message,
      } as FailureRes;
    });
}

// TODO consolidate with apolloQuery handler
export function apolloMutation<ReturnType, MutationVariables extends OperationVariables>(
  mutationOptions: MutationOptions<ReturnType, MutationVariables>,
  client: ApolloClient<object>
): Promise<AppReqResponse<FetchResult<ReturnType>>> {
  return client
    .mutate<ReturnType, MutationVariables>(mutationOptions)
    .then(body => {
      if (body.errors?.length) {
        throw {
          status: 400,
          statusText: body.errors[0].extensions?.code,
          message: body.errors.join(","),
        };
      }

      return {
        type: "success" as ResType,
        body,
      } as SuccessRes<FetchResult<ReturnType>>;
    })
    .catch(error => {
      const message = (error?.message as string) || "";
      const status = parseInt(error?.status, 10) || 400;

      return {
        type: "error" as ResType,
        status,
        message,
      } as FailureRes;
    });
}

export interface IShopifyUserError {
  field: string[];
  message: string;
}

export interface IApolloShopifyQueryReturnType {
  extensions: ShopifyExtensions;
}

export type IApolloShopifyMutationReturnType = {
  [rootKey: string]: { userErrors: IShopifyUserError[] };
} & { extensions: ShopifyExtensions };

export function apolloShopifyQuery<ReturnType, QueryVariables extends OperationVariables>(
  queryOptions: QueryOptions<QueryVariables>,
  client: ApolloClient<object>
) {
  return apolloQuery<ReturnType & IApolloShopifyQueryReturnType, QueryVariables>(queryOptions, client);
}

export function apolloShopifyMutation<ReturnType, MutationVariables extends OperationVariables>(
  mutationOptions: MutationOptions<ReturnType & IApolloShopifyMutationReturnType, MutationVariables>,
  client: ApolloClient<object>,
  throwOnFail = false
): Promise<AppReqResponse<FetchResult<ReturnType & IApolloShopifyMutationReturnType>>> {
  const apolloPromise = apolloMutation<ReturnType & IApolloShopifyMutationReturnType, MutationVariables>(
    mutationOptions,
    client
  );

  if (!throwOnFail) {
    return apolloPromise;
  }
  return apolloPromise.then(result => {
    if (result.type === "error") {
      throw new SurfaceableError({ message: result.message, status: result.status });
    } else {
      return result;
    }
  });
}

export async function throws<ReturnType>(
  resultPromise: Promise<AppReqResponse<FetchResult<ReturnType & IApolloShopifyMutationReturnType>>>
) {
  const result = await resultPromise;
  if (result.type === "error") {
    throw new SurfaceableError({ message: result.message, status: result.status });
  }

  // there should be 1 root key being returned from the mutation
  // this would break down if we later decide to do multiple named operations
  // in a single query
  const key = Object.keys(result.body.data ?? {})[0];

  const userErrors = result.body.data?.[key]?.userErrors;
  if (userErrors?.length) {
    throw new SurfaceableError({ message: JSON.stringify(userErrors), status: StatusCodes.BAD_REQUEST });
  }

  return result;
}

export function createApolloShopifyExtensionLink(
  uri: string,
  headers: { [key: string]: string },
  fetch?: FetchFunction
) {
  const extensionLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      if (response.extensions) {
        response.data = {
          ...response.data,
          extensions: response.extensions,
        };
      }
      return response;
    });
  });

  // RetryLink only retries operational/network errors (not GraphQL errors), so we use this link to "promote" a
  // max cost GraphQL error to an operational error so the retry link is invoked
  // https://github.com/apollographql/apollo-link/issues/541#issuecomment-392166160
  const promoteErrorLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const errorCodes = ["MAX_COST_EXCEEDED", "THROTTLED"];
      const errors = response.errors;

      if (errors) {
        const hasError = errors.some(error => {
          const errorCode = error.extensions?.code?.toUpperCase();
          const errorMessage = error.message?.toUpperCase();
          return errorCodes.includes(errorCode) || errorMessage === "THROTTLED";
        });

        if (hasError) {
          throw errors[0];
        }
      }

      return response;
    });
  });

  const retryLink = new RetryLink({
    delay: {
      initial: APOLLO_RETRY_INITIAL_DELAY,
      max: APOLLO_RETRY_MAX_DELAY,
      jitter: true,
    },
    attempts: {
      max: APOLLO_RETRY_MAX_ATTEMPTS,
      retryIf: (error, _operation) => !!error,
    },
  });

  return ApolloLink.from([
    extensionLink,
    retryLink,
    promoteErrorLink,
    new HttpLink({
      uri,
      fetch,
      headers: {
        ...headers,
        "content-type": "application/json", // must be lowercase, https://github.com/apollographql/apollo-link/issues/249
      },
    }),
  ]);
}

type FetchFunction = typeof fetch;
